import React, { useEffect, useState } from "react";
import {AppDispatch} from "app/redux_store";
import {useAppDispatch, useAppSelector} from "app/hook";
import {
    changeStep,
    goBack,
    resetGotoProductInstructions,
    resetSetOrderComplete,
    resetState,
    resetStepChange,
    setAmount,
    setCommission,
    setExtraCharge,
    setIsNavigate,
    setShipmentDate
} from "./redux/completion.slice";
import {complete, initData, releaseLock,} from "./redux/completion.thunks";
import {
    ConfigurationStepperComponent
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {DropdownComponent, FlexBox, Left, PrimaryButtonComponent, Right, TextBoxComponent} from "shared/shared";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import {INavigationParams} from "configurations/shared/configuration_editor/models/navigation_params.model";
import {ContentContainer} from "shared/components/container/ContentContainer";
import {LabeledData} from "shared/components/LabeledData";
import {DatePickerComponent} from "../../../../../shared/components/date_picker/datepicker.component";
import {Dialog, DialogContent, DialogTitle, Grid, Tooltip, Typography} from "@mui/material";
import IConfiguration from "../../../../../models/configurations/configuration";
import {ConfigurationMode} from "../../../configurationMode.model";
import ErrorDialog from "shared/components/error_dialog/error_dialog.component";
import {LoadingOverlayContainer} from "shared/components/loading_overlay/loading_overlay.component";
import {LoadingOverlayComponent} from "../../../../../shared/components/loading_overlay/loading_overlay.component";
import {ConfigurationNavigationComponent} from "../../components/configuration_navigation.component";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import {IconButton} from "../../../../../shared/components/icons/colored_icon.component";
import {DocumentModel} from "../../../../../models/documentModel";
import {saveAs} from "file-saver";
import {DocumentPreviewDialog} from "../../../configuration_documents/documentPreviewDialog.component";
import {
    getPreviewableAdditionsDocuments,
    getPreviewableProductionInstructionsDocuments
} from "../../../configuration_documents/previewFiletypeFilter";
import {updateAvailableSteps, updateStepperState} from "../../redux/stepper.slice";
import {useTranslation} from "react-i18next";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import {QuantityModel} from "models/quantity.model";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";
import { HideOnExclusiveMode } from "sales/hideonexclusivemode.component";
import * as style from "../../components/configuration_navigation.style";
import colors from "shared/style/colors";
import ExtraChargeModel from "models/extraChargeModel";
import { useNavigate } from "react-router-dom";

interface CompletionProps {
    configurationId: string;
    onNavigate: (next: StepDescriptor, params?: INavigationParams[]) => void;
    getCustomerData: (orderConfiguration: IConfiguration) => void;
    isConfigurationReadonly: boolean;
    onceFinalized: boolean;
    configurationState: ConfigurationStepEnum;
}

export const CompletionComponent: React.FC<CompletionProps> = (props) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.configuration_completion);
    const stepperState = useAppSelector((state) => state.configuration_stepper);
    const configurationMode = useAppSelector((state) => state.configuration_mode).mode;
    const exclusiveMode = useAppSelector((state) => state.configuration_mode).exclusive;
    const [displayErrorDialog, setDisplayErrorDialog] = useState({
        isVisible: false,
        errorMessage: "",
    });
    const [isAdditionDocumentDialogOpen, setIsAdditionDocumentDialogOpen] = useState(false);
    const [isProductionInstructionDocumentDialogOpen, setIsProductionInstructionDocumentDialogOpen] = useState(false);
    const [firstAdditionDocumentIndex, setFirstAdditionDocumentIndex] = useState(0);
    const [firstProductionInstructionDocumentIndex, setFirstProductionInstructionDocumentIndex] = useState(0);
    const {apiError} = useApiError();
    const {hasPendingChanges, setPendingChanges} = usePendingChanges();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(updateStepperState(ConfigurationStepEnum.Completion));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }; // on exit component
    }, [dispatch]);

    useEffect(() => {
        if (!state.loadedData.isNavigating && props.configurationId) {
            dispatch(initData(props.configurationId));
        }
    }, [props.configurationId]);

    useEffect(() => {
        if (state.loadedData?.configuration) {
            props.getCustomerData(state.loadedData.configuration);
        }
    }, [state.loadedData]);

    useEffect(() => {
        if (state.loadedData.configuration !== null) {
            if (state.loadedData.configuration.productionTypeEnum !== null && state.loadedData.configuration.productionType !== undefined) {
                dispatch(updateAvailableSteps(state.loadedData.configuration.productionTypeEnum));
            }
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        }
        else if (state.query.init.status === "error") {
            showError(state.query.init.message);
        } else {
            hideError();
        }
    }, [state.query.get.status, state.query.init.status]);

    useEffect(() => {
        if (state.command.goBackToProductInstructions.status === "success") {
            const target = stepperState.previousStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(resetGotoProductInstructions());
        }
    }, [state.command.goBackToProductInstructions.status]);

    useEffect(() => {
        if (state.command.ordercomplete.status === "success") {
            dispatch(releaseLock(props.configurationId));
            if (!exclusiveMode) {
                dispatch(setIsNavigate());
                navigate("/home");
            }
        }
        if (state.command.ordercomplete.status === "error") {
            showError(state.command.ordercomplete.message);
            dispatch(resetSetOrderComplete());
        }
    }, [state.command.ordercomplete.status]);

    useEffect(() => {
        if (state.command.releaseLock.status === "success") {
            if (!exclusiveMode) {
                dispatch(setIsNavigate());
                navigate("/home");
            }
        }
    }, [state.command.releaseLock.status]);

    useEffect(() => {
        if (state.actualData.handleStepChange === true) {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(updateStepperState(target.value));
            dispatch(resetStepChange());
        }
    }, [state.actualData.handleStepChange]);

    const handleDownloadClick = (downloadDocument: DocumentModel) => {
        const blob = new Blob([downloadDocument.document]);
        saveAs(blob, downloadDocument.name);
    };

    const handleDialogClose = () => {
        setIsAdditionDocumentDialogOpen(false);
        setIsProductionInstructionDocumentDialogOpen(false);
    };

    const handlePreviewClick = (index) => {
        // Assuming you have a state to manage the index of the clicked item
        setFirstProductionInstructionDocumentIndex(index);
        setIsProductionInstructionDocumentDialogOpen(true);
    };

    if (state.command.ordercomplete.status === "success" && exclusiveMode) {
        return (<Dialog open={true}>
            <DialogTitle>{t("AppName")}</DialogTitle>
            <DialogContent><p style={{whiteSpace: "pre-wrap"}}>{t("MessageConfigurationCompleted")}</p></DialogContent>
        </Dialog>);
    }

console.log("readonly ", props.isConfigurationReadonly , " onceFInalized ", props.onceFinalized);

    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {state.actualData.isLoading || state.actualData.isInitializing ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            <ConfigurationStepperComponent
                                onStepChangeExecute={(step: IStepChange) => dispatch(changeStep(step))}
                                orderConfigurationStateStep={props.configurationState}/>

                            <ContentContainer>
                                <Grid rowSpacing={1} columnSpacing={5} container>
                                    <LabeledData
                                        labelPlaceholder={"COMMON:Sku"}>{state.loadedData.configuration.sku}</LabeledData>
                                    <LabeledData
                                        labelPlaceholder={"COMMON:SkuLongIdentifier"}>{state.loadedData.configuration.productName}</LabeledData>
                                    <LabeledData
                                        labelPlaceholder={"COMMON:Ean"}>{state.loadedData.configuration.gtin}</LabeledData>

                                    {(state.loadedData.configuration.medicalAidNumbers.length > 0 || state.actualData.isScarTreatment)  && (
                                        <LabeledData
                                            labelPlaceholder={"COMMON:MedicalAidNumbers"}>
                                            <Grid container direction="row" key="medicalAidNumbers">
                                                {state.loadedData.configuration.medicalAidNumbers.length > 0 && <Grid item xs={6}>
                                                    {state.loadedData.configuration.medicalAidNumbers.join(", ")}
                                                </Grid>}
                                                {state.actualData.isScarTreatment && (<Grid item xs={6}>
                                                    <Typography  color={colors.mediMagenta}>
                                                        {t("COMMON:ScarTreatment")}
                                                    </Typography>
                                                </Grid>)} 
                                            </Grid>
                                                
                                        </LabeledData>
                                    )}

                                    {state.loadedData.configuration.additions.length > 0 && (
                                        <LabeledData labelPlaceholder={"COMMON:Additions"}>
                                            {state.loadedData.configuration.additions.map((addition) => (
                                                <Grid container direction="row" key={addition.text}>
                                                    <Grid item xs={6}>
                                                        {addition.text}
                                                    </Grid>
                                                    <Grid item xs={2}/>
                                                    {addition.positions.map((position) => (

                                                        <Grid key={position.text} justifyContent="flex-end" container
                                                              direction="column"
                                                              xs={4}>
                                                            {position.documents.map((document, index) => (
                                                                <Grid className="downloadable" container
                                                                      direction="row" justifyContent="space-between"
                                                                      alignItems="center" key={document.documentId}>
                                                                    <Typography maxWidth={100} noWrap>
                                                                        {document.name}
                                                                    </Typography>
                                                                    <div>
                                                                        {document.isPreviewable && <IconButton>
                                                                            <SearchIcon
                                                                                onClick={() => {
                                                                                    setFirstAdditionDocumentIndex(index);
                                                                                    setIsAdditionDocumentDialogOpen(true);
                                                                                }}
                                                                            />
                                                                        </IconButton>}
                                                                        <IconButton>
                                                                            <DownloadIcon
                                                                                onClick={() => handleDownloadClick(document)}/>
                                                                        </IconButton>
                                                                    </div>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            ))}
                                        </LabeledData>
                                    )}

                                    {state.loadedData.configuration.productionInstructions.length > 0 && (

                                        <LabeledData labelPlaceholder={"COMMON:ProductionInstructions"}>


                                            <Grid container direction="row">
                                                <Grid item xs={12}>
                                                    {state.loadedData.configuration.productionInstructions
                                                        .filter((p,) => p.freeText === "" && p.documents.length === 0)
                                                        .map((p, index, filteredInstructions) => {
                                                            return (
                                                                <>
                                                                    <Tooltip title={p.instruction} key={p.symbol}>
                                                                    <span
                                                                        className="instructionSymbol">{p.symbol}</span>
                                                                    </Tooltip>
                                                                    {index < filteredInstructions.length - 1 && ", "}
                                                                </>
                                                            );
                                                        })}
                                                </Grid>
                                            </Grid>


                                            {state.loadedData.configuration.productionInstructions.map((p) => {
                                                const showDocumentInstructions = p.freeText === "" && p.documents.length !== 0;
                                                const showFreeTextInstructions = p.freeText !== "" && p.documents.length === 0;
                                                const showBothInstructions = p.freeText !== "" && p.documents.length !== 0;

                                                return (
                                                    <Grid container direction="row" key={p.symbol}>
                                                        {showDocumentInstructions && (
                                                            <>
                                                                <Grid item xs={2}>
                                                                    <Tooltip title={p.instruction}>
                                                                    <span
                                                                        className="instructionSymbol">{p.symbol}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6}></Grid>
                                                                <Grid item xs={4}>
                                                                    {p.documents.map((document, documentIndex) => (
                                                                        <Grid className="downloadable" container
                                                                              direction="row"
                                                                              justifyContent="space-between"
                                                                              alignItems="center"
                                                                              key={document.documentId}>
                                                                            <Typography maxWidth={100} noWrap>
                                                                                {document.name}
                                                                            </Typography>
                                                                            <div>
                                                                                {document.isPreviewable && (
                                                                                    <IconButton
                                                                                        onClick={() => handlePreviewClick(documentIndex)}>
                                                                                        <SearchIcon/>
                                                                                    </IconButton>
                                                                                )}
                                                                                <IconButton
                                                                                    onClick={() => handleDownloadClick(document)}>
                                                                                    <DownloadIcon/>
                                                                                </IconButton>
                                                                            </div>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </>
                                                        )}

                                                        {showFreeTextInstructions && (
                                                            <>
                                                                <Grid item xs={2}>
                                                                    <Tooltip title={p.instruction}>
                                                                    <span
                                                                        className="instructionSymbol">{p.symbol}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    {p.freeText}
                                                                </Grid>
                                                            </>
                                                        )}

                                                        {showBothInstructions && (
                                                            <>
                                                                <Grid item xs={2}>
                                                                    <Tooltip title={p.instruction}>
                                                                    <span
                                                                        className="instructionSymbol">{p.symbol}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    {p.freeText}
                                                                </Grid>
                                                                <Grid item xs={2}></Grid>
                                                                <Grid item xs={4}>
                                                                    {p.documents.map((document, documentIndex) => (
                                                                        <Grid className="downloadable" container
                                                                              direction="row"
                                                                              justifyContent="space-between"
                                                                              alignItems="center"
                                                                              key={document.documentId}>
                                                                            <Typography maxWidth={100} noWrap>
                                                                                {document.name}
                                                                            </Typography>
                                                                            <div>
                                                                                {document.isPreviewable && (
                                                                                    <IconButton
                                                                                        onClick={() => handlePreviewClick(documentIndex)}>
                                                                                        <SearchIcon/>
                                                                                    </IconButton>
                                                                                )}
                                                                                <IconButton
                                                                                    onClick={() => handleDownloadClick(document)}>
                                                                                    <DownloadIcon/>
                                                                                </IconButton>
                                                                            </div>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </LabeledData>
                                    )}

                                    {state.loadedData.configuration.massMaskInformations !== undefined && (
                                        state.loadedData.configuration.massMaskInformations.map((massmaskInformation) => {
                                            if (massmaskInformation.entries.length === 1) {
                                                const entry = massmaskInformation.entries[0];
                                                if (!(entry.name.startsWith("size") || entry.name.startsWith("age") || entry.name.startsWith("weight")) || entry.value !== 0) {
                                                    return (
                                                        <LabeledData key={massmaskInformation.name}
                                                                     labelPlaceholder={massmaskInformation.name}>
                                                            {entry.textValue || entry.value || entry.name}
                                                        </LabeledData>
                                                    );
                                                }
                                            } else if (massmaskInformation.entries.length > 1) {
                                                return massmaskInformation.entries.map((entry, i) => {
                                                    if (entry.value !== 0) {
                                                        return (
                                                            <LabeledData key={i} labelPlaceholder={entry.name}>
                                                                {entry.textValue || entry.value}
                                                            </LabeledData>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                });
                                            }
                                        })
                                    )}


                                    {state.loadedData.configuration.productProperties.map((property) => (
                                        <LabeledData key={property.displayName}
                                                     labelPlaceholder={property.data}>{property.displayName}</LabeledData>
                                    ))}

                                    {configurationMode !== ConfigurationMode.QuotationConfiguration && (
                                        <LabeledData labelPlaceholder={"COMMON:ShipmentDate"}>
                                            <DatePickerComponent
                                                currentlySelectedDate={new Date(state.actualData.shipmentDate)}
                                                minDate={state.loadedData.configuration.displayShipmentDate.minDate ? new Date(state.loadedData.configuration.displayShipmentDate.minDate) : null}
                                                maxDate={state.loadedData.configuration.displayShipmentDate.maxDate ? new Date(state.loadedData.configuration.displayShipmentDate.maxDate) : null}
                                                onSelect={(date) => {
                                                    dispatch(setShipmentDate(date.toISOString().split("T")[0]));
                                                    setPendingChanges(true);
                                                }}
                                                disabled={props.isConfigurationReadonly || props.onceFinalized}
                                            />
                                        </LabeledData>
                                    )}

                                    <LabeledData labelPlaceholder={"COMMON:Amount"}>
                                        <DropdownComponent
                                            name={""}
                                            displayedProperties={["displayName"]}
                                            data={state.loadedData.configuration.displayQuantity}
                                            onSelect={(amount) => {
                                                dispatch(setAmount(amount));
                                                setPendingChanges(true);
                                            }}
                                            style={{marginTop: "-5px"}}
                                            selectedValue={state.actualData.amount}
                                            equalityComparer={(option: QuantityModel, value: QuantityModel) => {
                                                return option?.data == value?.data;
                                            }}
                                            isReadonly={props.isConfigurationReadonly}
                                        />
                                    </LabeledData>

                                    <LabeledData labelPlaceholder={"COMMON:Commission"}>
                                        <TextBoxComponent
                                            name={t("")}
                                            onChange={(commissionText) => {
                                                dispatch(setCommission(commissionText));
                                                setPendingChanges(true);
                                            }}
                                            value={state.actualData.commission}
                                            isRequired={false}
                                            maxLength={100}
                                            tabIndex={5}
                                            style={{marginTop: "-20px"}}
                                            isReadonly={props.isConfigurationReadonly}
                                        />
                                    </LabeledData>

                                    <LabeledData labelPlaceholder={"COMMON:ExtraCharge"}>
                                        <DropdownComponent
                                            name={""}
                                            displayedProperties={["displayName"]}
                                            data={state.loadedData.configuration.displayExtraCharge}
                                            onSelect={(extraCharge) => {
                                                dispatch(setExtraCharge(extraCharge));
                                                setPendingChanges(true);
                                            }}
                                            style={{marginTop: "-5px"}}
                                            selectedValue={state.actualData.extraCharge}
                                            equalityComparer={(option: ExtraChargeModel, value: ExtraChargeModel) => {
                                                return option?.data === value?.data;
                                            }}
                                            isReadonly={props.isConfigurationReadonly}
                                            isRequired={true}
                                        />
                                    </LabeledData>
                                </Grid>

                                <HideOnExclusiveMode>
                                <style.NavigationButtonArea>
                                    <FlexBox>
                                        <Left>                                            
                                        </Left>
                                        <Right>
                                        <PrimaryButtonComponent key="complete" onClick={() => dispatch(complete({id: props.configurationId, finalize: false}))} disabled={!(state.command.ordercomplete.canExecute && !props.isConfigurationReadonly)}>
                                                    {t("ButtonSave")}
                                                </PrimaryButtonComponent>
                                        </Right>
                                    </FlexBox>
                                </style.NavigationButtonArea>
                                </HideOnExclusiveMode>

                                <ConfigurationNavigationComponent
                                    canBack={true}
                                    back={() => dispatch(goBack(stepperState.previousStep))}
                                    next={() => dispatch(complete({id: props.configurationId, finalize: true}))}
                                    canNext={state.command.ordercomplete.canExecute && !props.isConfigurationReadonly}
                                    nextButtonLabel={t("OrderStepButtonComplete")}
                                    hasPendingChanges={hasPendingChanges}
                                />

                            </ContentContainer>

                            <DocumentPreviewDialog
                                documents={getPreviewableAdditionsDocuments(state.loadedData.configuration.additions)}
                                isOpenProp={isAdditionDocumentDialogOpen}
                                handleClose={handleDialogClose}
                                firstDocumentIndex={firstAdditionDocumentIndex}
                            />

                            <DocumentPreviewDialog
                                documents={getPreviewableProductionInstructionsDocuments(state.loadedData.configuration.productionInstructions)}
                                isOpenProp={isProductionInstructionDocumentDialogOpen}
                                handleClose={handleDialogClose}
                                firstDocumentIndex={firstProductionInstructionDocumentIndex}
                            />
                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>


    );
};
